import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { FormControl, ValidationErrors } from '@angular/forms';
import { ThinExchangeDeal } from '../../_models/models';
import { FormPartComponent } from '../form-part-component';
import { ExchangeDealsService } from '@/app/features/exchange-deals/_services/exchange-deals.service';
import { CreateQueryParams, RequestQueryBuilder } from '@libs/crud-request-nestjs';
import { initialExchangeDealsFilterQueryConfig } from '@/app/features/exchange-deals/_services/exchange-deals.config';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { DealsDropdownService } from '../../_services/deals-dropdown.service';
import dayjs from 'dayjs';

@Component({
  selector: 'app-exchange-deals-dropdown',
  templateUrl: './exchange-deals-dropdown.component.html',
  styleUrls: ['./exchange-deals-dropdown.component.less'],
  providers: FormPartComponent.createDirectiveProviders(ExchangeDealsDropdownComponent)
})
export class ExchangeDealsDropdownComponent extends FormPartComponent<number[], FormControl<number[]>> implements OnInit, OnDestroy {
  @Input() maxTagCount = 1;
  @Input() placeholder = 'Select bundled deals';
  @Input() showSearch = true;
  @Input() selectId = 'exchangeDealsSelect';
  @Input() pageSize = 50;

  currentPage = 1;
  searchText = '';
  isLoading = false;
  deals: ThinExchangeDeal[] = [];
  exchangeDealsQueryParams: CreateQueryParams = structuredClone(initialExchangeDealsFilterQueryConfig);
  private readonly _formPart = new FormControl<number[]>([]);
  private searchSubject$ = new Subject<string>();
  private destroy$ = new Subject<void>();
  private selectedDealsCache: ThinExchangeDeal[] = [];


  constructor(
    private exchangeDealsService: ExchangeDealsService,
    private dealsDropdownService: DealsDropdownService,
  ) {
    super();
  }

  protected override get formPart(): FormControl<number[]> {
    return this._formPart;
  }

  ngOnInit(): void {
    this.setupSearchDebounce();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onSearch(searchText: string): void {
    this.searchSubject$.next(searchText);
  }

  onScrollToBottom(): void {
    if (!this.isLoading) {
      this.currentPage++;
      this.getThinExchangeDeals();
    }
  }

  getHiddenItems(): number[] {
    const selectedDealIds = this.selectedDealsCache.map(deal => deal.dealId);
    return this.dealsDropdownService.getHiddenItems(selectedDealIds, this.maxTagCount);
  }

  getItemLabel(dealId: number): string {
    const deal = [...this.selectedDealsCache, ...this.deals].find(d => d.dealId === dealId);
    return deal ? this.formatDealLabel(deal, true) : '';
  }

  formatDealLabel(deal: ThinExchangeDeal, useHtml = false): string {
    return this.dealsDropdownService.formatDealLabel(deal, deal.description || deal.name || '', useHtml);
  }

  isInactive(deal: ThinExchangeDeal): boolean {
    return this.dealsDropdownService.isInactive(deal);
  }

  isItemDisabled(dealId: number): boolean {
    if (this.isSelected(dealId)) {
      return false;
    }

    const deal = this.deals.find(d => d.dealId === dealId);
    if (!deal) {
      return false;
    }
    return this.dealsDropdownService.isInactive(deal);
  }


  override writeValue(value: number[]): void {
    super.writeValue(value);
    if (value?.length) {
      this.loadSelectedDeals(value);
    } else {
      this.selectedDealsCache = [];
    }
  }

  override registerOnChange(fn: (value: number[]) => void): void {
    super.registerOnChange((value: number[]) => {
      const newlySelectedDeals = (value || [])
        .filter(dealId => !this.selectedDealsCache.some(d => d.dealId === dealId))
        .map(dealId => this.deals.find(d => d.dealId === dealId))
        .filter(deal => deal !== undefined);

      if (newlySelectedDeals.length > 0) {
        this.selectedDealsCache = [...this.selectedDealsCache, ...newlySelectedDeals];
      }

      const currentSelectedIds = new Set(value || []);
      this.selectedDealsCache = this.selectedDealsCache.filter(deal =>
        currentSelectedIds.has(deal.dealId)
      );

      fn(value);
    });
  }

  protected override getFormPartValidationErrors(): ValidationErrors {
    return null;
  }

  protected isSelected(dealId: number): boolean {
    const selectedValues = this.formPart.value || [];
    return selectedValues.includes(dealId);
  }

  private setupSearchDebounce(): void {
    const SEARCH_DEBOUNCE_MS = 700;

    this.searchSubject$.pipe(
      debounceTime(SEARCH_DEBOUNCE_MS),
      distinctUntilChanged(),
      takeUntil(this.destroy$)
    ).subscribe(searchText => {
      this.searchText = searchText;
      this.currentPage = 1;
      this.handleSearchUpdate();
    });
  }

  private handleSearchUpdate(): void {
    const hasSelectedDealsAndNoSearch = !this.searchText && this.formPart.value?.length;

    if (hasSelectedDealsAndNoSearch) {
      this.loadSelectedDeals(this.formPart.value);
    }

    this.getThinExchangeDeals();
  }

  private updatePaginationParams(): void {
    this.exchangeDealsQueryParams.page = this.currentPage;
    this.exchangeDealsQueryParams.limit = this.pageSize;
    this.exchangeDealsQueryParams.sort = [
      { field: 'name', order: 'ASC' },
      { field: 'description', order: 'ASC' },
    ];
  }

  private updateSearchParams(): void {
    const currentDateTime = this.getCurrentFormattedDateTime();
    const baseSearch = structuredClone(initialExchangeDealsFilterQueryConfig.search || {});

    this.exchangeDealsQueryParams.search = {
      ...baseSearch,
      dealEnd: { $gt: currentDateTime },
      inventoryType: { $eq: 'unruly_ctrl_deal' },
      status: { $in: ['ACTIVE'] },
      dealPartnerId: { $isnull: true }
    };

    if (this.searchText) {
      this.addTextSearchFilters();
    }
  }

  private getCurrentFormattedDateTime(): string {
    return dayjs().tz('America/New_York').toDate().toISOString();
  }

  private addTextSearchFilters(): void {
    const searchPattern = `%${this.searchText}%`;
    const textSearchFields = {
      $or: [
        { externalDealId: { $contL: searchPattern } },
        { name: { $contL: searchPattern } },
        { description: { $contL: searchPattern } }
      ]
    };

    Object.assign(this.exchangeDealsQueryParams.search, textSearchFields);
  }

  private updateDealsData(newDeals: ThinExchangeDeal[]): void {
    if (!Array.isArray(newDeals)) {
      return;
    }

    if (this.searchText) {
      this.updateDealsForSearchMode(newDeals);
    } else {
      this.updateDealsForNormalMode(newDeals);
    }
  }

  private updateDealsForSearchMode(newDeals: ThinExchangeDeal[]): void {
    if (this.currentPage === 1) {
      this.deals = newDeals;
      return;
    }

    if (newDeals.length > 0) {
      const uniqueNewDeals = this.filterUniqueDeals(newDeals, this.deals);
      this.deals = [...this.deals, ...uniqueNewDeals];
    }
  }

  private updateDealsForNormalMode(newDeals: ThinExchangeDeal[]): void {
    const uniqueNewDeals = this.filterUniqueDeals(
      newDeals,
      this.currentPage === 1 ? this.selectedDealsCache : this.deals
    );

    this.deals = this.currentPage === 1
      ? [...this.selectedDealsCache, ...uniqueNewDeals]
      : [...this.deals, ...uniqueNewDeals];
  }

  private async getThinExchangeDeals(): Promise<void> {
    try {
      this.updatePaginationParams();
      this.updateSearchParams();

      const filters = RequestQueryBuilder.create(this.exchangeDealsQueryParams).query();
      this.isLoading = true;
      const response = await this.exchangeDealsService.getThinExchangeDeals(filters).toPromise();
      this.updateDealsData(response?.data || []);
    } catch (error) {
      console.error('Failed to fetch exchange deals:', error);
    } finally {
      this.isLoading = false;
    }
  }

  private filterUniqueDeals(newDeals: ThinExchangeDeal[], existingDeals: ThinExchangeDeal[]): ThinExchangeDeal[] {
    return newDeals.filter(newDeal =>
      !existingDeals.some(existing => existing.dealId === newDeal.dealId)
    );
  }

  private async loadSelectedDeals(missingDealIds: number[]): Promise<void> {
    if (!missingDealIds?.length) {
      return;
    }

    try {
      const baseConfig = structuredClone(initialExchangeDealsFilterQueryConfig);
      baseConfig.limit = missingDealIds.length;

      const filters = RequestQueryBuilder.create({
        search: {
          ...baseConfig.search,
          status: { $in: ['ACTIVE', 'INACTIVE'] },
          inventoryType: { $eq: 'unruly_ctrl_deal' },
          dealPartnerId: { $isnull: true },
          dealId: { $in: missingDealIds },
        },
        limit: baseConfig.limit
      }).query();

      this.isLoading = true;

      const response = await this.exchangeDealsService.getThinExchangeDeals(filters).toPromise();
      const newSelectedDeals = (response?.data || []).filter(deal => 
        deal.status.toLowerCase() !== 'forecast'
      );
      const uniqueNewDeals = this.filterUniqueDeals(newSelectedDeals, this.selectedDealsCache);

      if (uniqueNewDeals.length > 0) {
        this.selectedDealsCache = [...uniqueNewDeals, ...this.selectedDealsCache];
      }

      if (!this.searchText) {
        const uniqueDealsForList = this.filterUniqueDeals(newSelectedDeals, this.deals);
        if (uniqueDealsForList.length > 0) {
          this.deals = [...this.deals, ...uniqueDealsForList];
        }
      }
    } catch (error) {
      console.error('Failed to load selected deals:', error);
    } finally {
      this.isLoading = false;
    }
  }
}
