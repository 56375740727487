<nz-select
  nzShowArrow="false"
  class="full-width"
  nzMode="multiple"
  nzServerSearch
  [formControl]="formPart"
  [nzMaxTagCount]="maxTagCount"
  [nzShowSearch]="showSearch"
  [nzPlaceHolder]="placeholder"
  [nzMaxTagPlaceholder]="tagPlaceHolder"
  [id]="selectId"
  [nzLoading]="isLoading"
  (nzOnSearch)="onSearch($event)"
  (nzScrollToBottom)="onScrollToBottom()">

  <nz-option *ngFor="let deal of deals"
    [nzLabel]="formatDealLabel(deal)"
    [nzValue]="deal.dealId"
    [nzDisabled]="isItemDisabled(deal.dealId)"
    nzCustomContent>
    <span [innerHTML]="formatDealLabel(deal, true)"></span>
  </nz-option>
</nz-select>

<ng-template #tagPlaceHolder let-selectedList>
  <span nz-popover
        [nzPopoverTitle]="'More deals'"
        [nzPopoverContent]="moreList"
        nzPopoverTrigger="hover"
        class="more-deals-tag">
    {{ selectedList.length }} more
  </span>
</ng-template>

<ng-template #moreList>
  <div class="display-flex flex-column">
    <ng-container *ngFor="let dealId of getHiddenItems()">
      <span [innerHTML]="getItemLabel(dealId)">
      </span>
    </ng-container>
  </div>
</ng-template>
