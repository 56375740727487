import { Injectable } from '@angular/core';
import { ThinExchangeDeal, UnrulyBundledDeal } from '../_models/models';

@Injectable({
  providedIn: 'root'
})
export class DealsDropdownService {
  getHiddenItems(selectedValues: number[], maxTagCount: number): number[] {
    return selectedValues.slice(maxTagCount);
  }

  formatDealLabel(deal: ThinExchangeDeal | UnrulyBundledDeal, description: string, useHtml = false): string {
    const statusText = this.isInactive(deal)
      ? useHtml ? ' <b>(inactive)</b>' : ' (inactive)'
      : '';
    return `${description} (id: ${deal.externalDealId})${statusText}`;
  }

  isInactive(deal: ThinExchangeDeal | UnrulyBundledDeal): boolean {
    return deal.status.toLowerCase() === 'inactive' || 
           (deal.dealEnd && new Date(deal.dealEnd) < new Date());
  }

  isDealDisabled(deal: ThinExchangeDeal | UnrulyBundledDeal): boolean {
    if (!deal) return false;
    return this.isInactive(deal);
  }
} 