import { Component, Input, OnDestroy } from '@angular/core';
import { FormControl, ValidationErrors } from '@angular/forms';
import { FormPartComponent } from '../form-part-component';
import { UnrulyBundledDeal } from '@shared/_models/models';
import { Subject } from 'rxjs';
import { DealsDropdownService } from '../../_services/deals-dropdown.service';

@Component({
  selector: 'app-publisher-deals-dropdown',
  templateUrl: './publisher-deals-dropdown.component.html',
  providers: FormPartComponent.createDirectiveProviders(PublisherDealsDropdownComponent)
})
export class PublisherDealsDropdownComponent extends FormPartComponent<number[], FormControl<number[]>> implements OnDestroy {
  protected readonly maxTagCount = 1;
  @Input() placeholder = 'Select publisher deals';
  @Input() showSearch = true;
  @Input() selectId = 'publisherDealsSelect';
  @Input() unrulyBundledDeals: UnrulyBundledDeal[] = [];
  @Input() isLoading = false;

  private readonly _formPart = new FormControl<number[]>([]);
  private destroy$ = new Subject<void>();

  constructor(
    private dealsDropdownService: DealsDropdownService
  ) {
    super();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  protected override get formPart(): FormControl<number[]> {
    return this._formPart;
  }

  protected override getFormPartValidationErrors(): ValidationErrors {
    return null;
  }

  getHiddenItems(): number[] {
    return this.dealsDropdownService.getHiddenItems(this.formPart?.value || [], this.maxTagCount);
  }

  formatDealLabel(deal: UnrulyBundledDeal, useHtml = false): string {
    return this.dealsDropdownService.formatDealLabel(deal, deal.description, useHtml);
  }

  getItemLabel(dealId: number, useHtml = false): string {
    const deal = this.unrulyBundledDeals.find(d => d.pubDealId === dealId);
    return deal ? this.formatDealLabel(deal, useHtml) : '';
  }

  isInactive(deal: UnrulyBundledDeal): boolean {
    return this.dealsDropdownService.isInactive(deal);
  }

  isDealDisabled(deal: UnrulyBundledDeal): boolean {
    if (!deal) return false;
    
    const selectedValues = this.formPart?.value || [];
    if (selectedValues.includes(deal.pubDealId)) {
      return false;
    }
    
    return this.dealsDropdownService.isDealDisabled(deal);
  }
} 