import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpRequest, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { NzUploadFile } from 'ng-zorro-antd/upload';

@Injectable({ providedIn: 'root' })
export class FileUploadService {

  constructor(private http: HttpClient) {
  }

  uploadMultipleFiles(url: string, files: NzUploadFile[], otherParams: any = null, fileParamName: string = 'file'): Observable<HttpEvent<any>> {
    const formData = new FormData();

    files.forEach((file: any) => {
      formData.append('file', file);
      formData.append('docType', (otherParams?.docType) ? otherParams.docType : 'PAYMENT_TAX');
    });

    const options = {
      reportProgress: false,
    };

    const req = new HttpRequest('POST', url, formData, options);
    return this.http.request(req);
  }

}
