import { Injectable } from '@angular/core';
import { ApiService } from '../../../shared/_services/api.service';
import { Observable } from 'rxjs';
import {
  AudienceTargetingSegment,
  CloneExchangeDeal,
  CommonDataModel,
  ExchangeDeal,
  ThinExchangeDeal,
  OptionsList, PadAgreement,
  Personnel,
  ResponseFromServer,
  SupplyPublisherMedia,
  ThinPublisher
} from '../../../shared/_models/models';

@Injectable({providedIn: 'root'})

export class ExchangeDealsService {

  constructor( private api: ApiService ) {}

  createDeal(currentDeal: ExchangeDeal): Observable<ResponseFromServer<ExchangeDeal>> {
    return this.api.createExchangeDeal(currentDeal);
  }

  updateDeal(dealId: number, currentDeal: ExchangeDeal): Observable<ResponseFromServer<ExchangeDeal>> {
    return this.api.updateExchangeDeal(dealId, currentDeal);
  }

  cloneDeal(currentDeal: CloneExchangeDeal, dealId: number): Observable<ResponseFromServer<ExchangeDeal>> {
    return this.api.cloneExchangeDeal(currentDeal, dealId);
  }

  getCurrentDeal(dealId: number): Observable<ResponseFromServer<ExchangeDeal>> {
    return this.api.getCurrentExchangeDeal(dealId);
  }

  getCurrentPartnerForecastDeal(partnerForecastDealId: number): Observable<ResponseFromServer<ExchangeDeal>> {
    return this.api.getCurrentPartnerForecastDeal(partnerForecastDealId);
  }

  getDeviceTypes(): Observable<OptionsList> {
    return this.api.getDeviceTypes() as Observable<OptionsList>;
  }

  fetchExternalDealId(): Observable<ResponseFromServer<string>> {
    return this.api.getExternalDealId();
  }

  getAudienceTargeting(filters: string = ''): Observable<CommonDataModel<AudienceTargetingSegment[]>> {
    return this.api.getRxSegments(filters);
  }

  getExchangeDeals(filters: string = ''): Observable<CommonDataModel<ExchangeDeal[]>> {
    return this.api.getExchangeDeals(filters);
  }

  getThinExchangeDeals(filters: string = ''): Observable<CommonDataModel<ThinExchangeDeal[]>> {
    return this.api.getThinExchangeDeals(filters);
  }

  getExchangePersonnel(): Observable<ResponseFromServer<Personnel[]>> {
    return this.api.getExchangePersonnel();
  }

  getPublishers(query: any): Observable<ResponseFromServer<ThinPublisher[]>> {
    return this.api.getPublishers(query);
  }

  getExchangeDealPads(filters: string = ''): Observable<CommonDataModel<PadAgreement[]>> {
    return this.api.getExchangePads(filters);
  }

  getPublisherNamesByIds(publisherIds: string[]): Observable<ResponseFromServer<SupplyPublisherMedia[]>> {
    return this.api.getPublisherNamesByIds(publisherIds);
  }
}
