<nz-select
  [formControl]="formPart"
  [nzMaxTagCount]="maxTagCount"
  [nzShowSearch]="showSearch"
  nzMode="multiple"
  [nzPlaceHolder]="placeholder"
  [id]="selectId"
  nzShowArrow="false"
  class="full-width"
  [nzLoading]="isLoading"
  [nzMaxTagPlaceholder]="tagPlaceHolder">
  <nz-option *ngFor="let option of unrulyBundledDeals"
    [nzLabel]="formatDealLabel(option)"
    [nzValue]="option.pubDealId"
    [nzDisabled]="isDealDisabled(option)"
    nzCustomContent>
    <span [innerHTML]="formatDealLabel(option, true)"></span>
  </nz-option>
</nz-select>

<ng-template #tagPlaceHolder let-selectedList>
  <span nz-popover
        [nzPopoverTitle]="'More deals'"
        [nzPopoverContent]="moreList"
        nzPopoverTrigger="hover">
    {{ selectedList.length }} more
  </span>
</ng-template>

<ng-template #moreList>
  <div class="display-flex flex-column">
    <ng-container *ngFor="let dealId of getHiddenItems(); let isLast = last">
      <span [class.margin-bottom-small]="!isLast" [innerHTML]="getItemLabel(dealId, true)"></span>
    </ng-container>
  </div>
</ng-template> 